<template>
  <div class="rewardBox">
    <el-dialog title="" :visible.sync="ifshow" center>
      <div class="box">
        <div class="user">
          <avatar-item
            shape="square"
            :name="store.actVideoDec.userNickName"
            :addr="store.actVideoDec.userId"
            :size="60"
            :src="store.avatar"
          >
          </avatar-item>
          <div class="text">
            <div class="name">{{ store.actVideoDec.userNickName }}</div>
            <div class="addr">
              {{ uti.shrink(store.actVideoDec.userAddress, 6) }}
            </div>
          </div>
        </div>
        <div class="amountBox">
          <a href="javascript:;">
            <div
              @click="actItem(10)"
              :class="{ act: act == '10' }"
              class="amoutBut"
            >
              10 psc
            </div>
          </a>
          <a href="javascript:;">
            <div
              @click="actItem(20)"
              :class="{ act: act == '20' }"
              class="amoutBut"
            >
              20 psc
            </div>
          </a>
          <a href="javascript:;">
            <div
              @click="actItem(50)"
              :class="{ act: act == '50' }"
              class="amoutBut"
            >
              50 psc
            </div>
          </a>
        </div>
        <div class="amountBox">
          <a href="javascript:;">
            <div
              @click="actItem(100)"
              :class="{ act: act == '100' }"
              class="amoutBut"
            >
              100 psc
            </div>
          </a>
          <a href="javascript:;">
            <div
              @click="actItem(200)"
              :class="{ act: act == '200' }"
              class="amoutBut"
            >
              200 psc
            </div>
          </a>
          <a href="javascript:;">
            <div
              @click="actItem('zd')"
              :class="{ act: act == 'zd' }"
              class="amoutBut"
            >
              自定义
            </div>
          </a>
        </div>
        <el-input
          v-if="inputShow"
          placeholder="请输入打赏的金额"
          v-model="input"
          type="number"
        >
          <span class="el-input__icon" slot="suffix"> PSC </span>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setShang()">确认打赏</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Store from "../../store";
import avatarItem from "../avatar/avatar-item.vue";
import shangABI from "./shangABI";
export default {
  components: { avatarItem },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示
      ifshow: false,
      act: "10",
      // 是否打开输入
      inputShow: false,
      input: "10",
      web3: "",
      shangCont: "",
    };
  },
  methods: {
    up() {
      this.ifshow = true;
    },
    down() {
      this.ifshow = false;
    },
    // 选择某一个
    actItem(val) {
      this.act = val;
      if (val !== "zd") {
        this.input = val;
        this.inputShow = false;
      } else {
        this.input = "10";
        this.inputShow = true;
      }
    },
    // 打赏作者
    async setShang() {
      if (!this.store.user) {
        return this.$message.error("请先登录");
      }
      // if (Number(this.input) < 10) {
      //   return this.$message.error("打赏金额不小于10");
      // }
      // 构建网络
      this.web3 = new this.$web3("https://seed1.PolySmartChain.com");
      // 构建打赏合约
      this.shangCont = new this.web3.eth.Contract(
        shangABI,
        this.store.shangCont
      );
      console.log(this.shangCont);
      // 项目id ，接收地址，视频id
      let dt = await this.shangCont.methods
        .rewards(1, this.store.actVideoDec.userAddress, this.store.actVideoId)
        .encodeABI();

      let trans = {
        from: this.store.user.address,
        to: this.store.shangCont,
        value: this.web3.utils.toHex(this.uti.amountMul(this.input, 18)),
        data: dt,
      };

      console.log(trans);
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [trans],
        })
        .then(() => {
          this.$message.success("打赏成功");
          // this.setVcant();
          this.down();
        })
        .catch(() => {
          return this.$message.error("打赏失败");
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  /* 矩形 20 */

  width: 707px;

  // height: 755px;
  padding-bottom: 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  .box {
    // width: 747px;
    margin: 0 auto;
    .user {
      display: flex;
      align-items: center;
      .text {
        margin-left: 13px;
        .name {
          height: 35px;
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #333333;
        }
        .addr {
          height: 27px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
    }
    .amountBox {
      display: flex;
      justify-content: space-between;
      margin-top: 49px;
      .amoutBut {
        width: 170px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #ff7d41;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        /* 10 PSC */

        font-family: SourceHanSansCN-Medium;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #ff7d41;
      }
      .act {
        background-color: #ff7d41;
        color: #ffffff;
      }
    }
    .el-input {
      margin-top: 49px;
      /* 矩形 23 */
      height: 90px;
      border-radius: 5px;
      box-sizing: border-box;
      // border: 1px solid #ff7d41;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      input {
        height: 90px;
        outline: none;
        padding-right: 20px;
        padding-left: 39px;
        font-size: 20px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input:focus {
        border: 1px solid #ff7d41;
      }
      .el-input__suffix {
        right: 50px;
        font-size: 20px;
      }
    }
  }
  .el-button {
    width: 100%;
    height: 60px;
    font-size: 24px;
    background-color: #ff7d41;
    border: none;
  }
  .hui {
    background-color: #999;
  }
}
@media screen and (max-width: 700px) {
  /deep/ .el-dialog {
    width: 95%;

    .box {
      .user {
        height: 40px;
        .text {
          .user {
            .avatar-item.a2 {
              width: 40px !important;
              height: 40px !important;
            }
          }
          .name {
            font-size: 14px;
            height: 20px;
          }
          .addr {
            font-size: 12px;
            height: 20px;
          }
        }
        .el-avatar {
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
      .amountBox {
        margin-top: 20px;
        .amoutBut {
          flex: 1;
          width: 73px;
          height: 28px;
          border-radius: 14px;
          font-size: 12px;
          line-height: 28px;
        }
      }
      .el-input {
        margin-top: 49px;
        /* 矩形 23 */
        height: 42px;
        border-radius: 5px;
        box-sizing: border-box;
        // border: 1px solid #ff7d41;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        input {
          height: 42px;
          outline: none;
          padding-right: 16px;
          padding-left: 16px;
          font-size: 14px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input:focus {
          border: 1px solid #ff7d41;
        }
        .el-input__suffix {
          right: 10px;
          font-size: 14px;
        }
      }
    }
    .el-button {
      width: 100%;
      height: 42px;
      font-size: 14px;
      background-color: #ff7d41;
      border: none;
    }
  }
}
</style>
